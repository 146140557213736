<template>
  <div class="taplink-cont" :class='$mq'>
    <div class="container">
      <iframe class="video" src="https://www.youtube.com/embed/W-s8FmRHB2w" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
    </div>
    <router-link to="/beginners" class="button doll">Создать будуарку
      <div class="tp-icon"></div>
    </router-link>
    <router-link to="/bjd2" class="button doll second">Создать шарнирку
      <div class="tp-icon doll2"></div>
    </router-link>
    <a href="https://www.youtube.com/channel/UC-0xHId8Riqcdbr8Yj67AXg" class="button youtube">Мой Youtube канал
      <div class="tp-icon yt"></div>
    </a>
    <a href="https://vk.com/strasti_sharnir" class="button vk">Моя группа Вконтакте
      <div class="tp-icon vk"></div>
    </a>
  </div>
</template>

<style scoped>
.container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  box-sizing: border-box;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.taplink-cont {
  max-width: 800px;
  margin: 1em auto 10em auto;
  padding: 0 0.5em;
  font-size: 1em;
}

.taplink-cont.mobile {
  font-size: 0.9em;
}

.taplink-cont .button {
  width: 100%;
  box-sizing: border-box;
  border: none;
  padding: 0.5em 1em;
  margin: 0.5em 0;
  border-radius: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
}

.taplink-cont .button:hover {
  transform: scale(1.05);
}

.taplink-cont .button.doll {
  background-color: #482431;
  color: #ffb1a4;
}
  .taplink-cont .button.doll.second {
    background-color: #141249;
    color: #ffb1a4;
    margin-bottom: 3em;
  }
.taplink-cont .button.youtube {
  border: 2px solid #eb2222;
  color: #eb2222;
}

.taplink-cont .button.vk {
  border: 2px solid #436fa3;
  color: #436fa3;
}

.taplink-cont .tp-icon {
  background: url('~@/assets/taplink_icons.svg');
  width: 45px;
  height: 40px;
  background-size: 40px;
  position: relative;
  top: 50%;
  /* transform: translateY(-50%); */
  background-position-y: -20px;
  background-repeat: no-repeat;
  margin-left: 1em;
}

.taplink-cont .tp-icon.yt {
  background-position-y: -155px;
}

.taplink-cont .tp-icon.vk {
  background-position-y: -90px;
}
.taplink-cont .tp-icon.doll2 {
  background-position-y: -215px;
}
</style>